<template>
  <configurable-table
    :report="report"
  />
</template>

<script>
import { capital } from '@/api'
import ConfigurableTable from './ConfigurableTable'
import { Report } from './report'

export default {
  name: 'PageReportGoodsThirdIncome',
  components: {
    ConfigurableTable,
  },
  setup () {
    const queryFields = [
      {
        key: 'supplyShopId',
        type: 'select',
        title: '采集供应商',
        options: async () => {
          const { data } = await capital.searchSupplyShopOfMine()
          return data.map(x => ({
            name: x.name,
            value: x.shopId
          }))
        },
      },
      {
        key: 'productName',
        type: 'input',
        title: '商品名称',
      },
      {
        type: 'daterange',
        key: 'range',
        title: '结算日期',
      },
    ]

    const resultFields = [
      {
        key: 'productName',
        title: '商品名称',
      },
      {
        key: 'sale',
        title: '销售数量',
      },
      {
        key: 'settleAmount',
        title: '订单金额',
      },
      {
        key: 'supplyPrice',
        title: '成本价'
      },
      {
        key: 'tradeCommission',
        title: '分销佣金',
      },
      {
        key: 'serviceFee',
        title: '平台服务费'
      },
      {
        key: 'supplyShopName',
        title: '采集供应商'
      }
    ]

    return {
      capital,
      report: new Report(queryFields, resultFields, {
        key: 'orderIncomeId',
        queryApi: ({ range, ...others }) => {
          return capital.getThirdGoodsIncomeReport({
            ...others,
            ...(range.length ? {
              settleBeginTime: range[0].format('YYYY-MM-DD 00:00:00'),
              settleEndTime: range[1].format('YYYY-MM-DD 23:59:59')
            } : null)
          })
        },
        exportApi: ({ range, ...others }) => {
          return capital.exportThirdGoodsIncomeReport({
            ...others,
            ...(range.length ? {
              settleBeginTime: range[0].format('YYYY-MM-DD 00:00:00'),
              settleEndTime: range[1].format('YYYY-MM-DD 23:59:59')
            } : null)
          })
        }
      })
    }
  }
}
</script>

<style lang="css" scoped>

</style>
